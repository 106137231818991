const MarkerClusterer = require("js-marker-clusterer");

require("bootstrap");
require("lightcase");
require("slick-carousel");
require("blazy");
require("js-cookie");
require("./jquery-flexnav.js");
require("./front/front.js");
require("./front/form.js");
require("./front/plugins/map/leaflet.js");
require("./front/plugins/map/leaflet.markercluster.js");
require("./front/map.js");
require("./front/my-search.js");