
$(document).ready(function() {
    if($(".analysis_index").length) {
        var submitOnly = false; // La recherche se lance t'elle uniquement au submit  ?


        $(".analysis_index .search-submit").on("click", function(e) {
            e.preventDefault();
        });


        var analysisLinks = $(".analys_link ");
        var itemsIndexed = [];
        var input = $(".search-input");
        var submitButton = $(".search-submit");

        analysisLinks.each(function() {
            var item = $(this);
            var itemFullText = item.data('name') + " " + item.data('tube-synonymous');
            itemFullText = itemFullText.replace(/é|è|ë|ê/gi, 'e');
            itemFullText = itemFullText.replace(/ä|â/gi, 'a');
            var itemInfo = {
                id: item.attr('id'),
                text: itemFullText,
                name: item.data('name'),
            };
            itemsIndexed.push(itemInfo);
        });

        search(null);

        if(submitOnly) {
            submitButton.on("click", function(e) {
                search(e);
            });
        } else {
            input.on( 'keyup', function( e ) {
                search(e);
            });
        }



        function search(e = null) {
            if(e !== null) {
                if( e.keyCode == 13 ) // enter
                {
                    input.trigger( 'blur' );
                    return true;
                }
            }

            var searchVal = $.trim(input.val());
            searchVal = searchVal.replace(/é|è|ë|ê/gi, 'e');
            searchVal = searchVal.replace(/ä|â/gi, 'a');
            var regex = new RegExp(searchVal, 'gi');
            if( searchVal.length ) {
                itemsIndexed.forEach(function(element) {
                    var elem = element;
                    if(element.text.match(regex) !== null) {
                        if(!$("#" + elem.id).hasClass('result')) {
                            $("#" + elem.id).addClass('result')
                        }
                    } else {
                        $("#" + elem.id).removeClass('result')
                    }
                });
                if($(".analys_link.result").length === 0) {
                    $("#no-result-message").show();
                } else {
                    $("#no-result-message").hide();
                }
            } else {
                $(".analys_link").removeClass('result')
            }
        }
    }
});
