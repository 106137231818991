$(document).ready(function() {

    $('.control-label.required').append('<span class="text-danger"> *</span>');

    var forms = $('body').find('form');
    var recaptcha = $('#recaptcha-data');

    if(recaptcha.length > 0 && forms.length > 0) {
        forms.each(function(){
            var confirmationEl = $(this).find('input.recaptchaToken');
            confirmationEl.val(recaptcha.data('public'));
        });
    }

    // Invalid form scroll to error
    var scroll = false;
    var errors = $(document).find('.has-error');
    errors.each(function() {
        if (!scroll) {
            var el = $(this);
            var elOffset = el.offset().top;
            var elHeight = el.height();
            var windowHeight = $(window).height();
            var offset;
            if (elHeight < windowHeight) {
                offset = elOffset - ((windowHeight / 2) - (elHeight / 2));
            } else {
                offset = elOffset;
            }
            var speed = 700;
            $('html, body').animate({scrollTop: offset}, speed);
            scroll = true;
        }
    });

    // Convert label string checkbox to html for cgu
    var CGUlabel = $('.checkbox label[for=form_cgu-covid]');
    if (CGUlabel != null){
        var CGULabelText = CGUlabel.text();
        var CGUlabelInput = CGUlabel.children('input');
        CGUlabel.html(CGUlabelInput);
        CGUlabel.append($(CGULabelText));
    }
});