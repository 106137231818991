$(document).ready(function() {

    var mapData = $('#map-data');

    if (mapData.length > 0) {
        var mapConfiguration = {};
        var iteration = 0;
        $('#map-data .group-map').each(function () {
            var category = $(this);
            var categoryName = category.data('name');
            var categoryPoints = category.find('.point');
            categoryPoints.each(function (it) {
                var point = $(this);
                var pointName = point.data('marker-name');
                var pointLink = point.data('marker-link');
                var latitude = point.data('latitude');
                var longitude = point.data('longitude');
                var marker = point.data('marker');
                var markerType = point.data('marker-type');
                var markerCity = point.data('marker-city');
                var markerAddress = point.data('marker-address');
                var markerZipcode = point.data('marker-zipcode');
                var markerLinkItinerary = point.data('marker-link-itinerary');
                mapConfiguration[iteration] = {
                    'categoryName': categoryName,
                    'pointName': pointName,
                    'pointLink': pointLink,
                    'lat': latitude,
                    'lon': longitude,
                    'marker': marker,
                    'markerType': markerType,
                    'markerCity': markerCity,
                    'markerAddress': markerAddress,
                    'markerZipcode': markerZipcode,
                    'markerLinkItinerary': markerLinkItinerary
                };
                iteration++;
            });
        });
        var lat = $("#map-data").data("latitude");
        var lon = $("#map-data").data("longitude");

        if ($("#map-data").data("zoom-mobile") && $(window).width() < 768) {
            var zoom = $("#map-data").data("zoom-mobile") ? $("#map-data").data("zoom-mobile") : 10;
        } else {
            var zoom = $("#map-data").data("zoom") ? $("#map-data").data("zoom") : 11;
        }

        var macarte = null;
        var markerClusters;
        initMap(mapConfiguration);
    }

    function initMap(mapConfiguration) {
        macarte = L.map('map').setView([lat, lon], zoom); // markerClusters = L.markerClusterGroup();

        L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}', {
            attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
            minZoom: 6,
            maxZoom: 16
        }).addTo(macarte);
        var markerGroup = [];

        for (var index in mapConfiguration) {
            var config = mapConfiguration[index];
            var myIcon = L.icon({
                iconUrl: config.marker,
                iconSize: [30, 30],
                iconAnchor: [20, 25],
                className: "marker-" + config.markerType,
                popupAnchor: [-3, -20]
            }); // Remove if markerClusters

            var marker = L.marker([config.lat, config.lon], {
                icon: myIcon
            }).addTo(macarte);
            html = ""; // if(config.image !== "") {
            //     html += '<img src="'+config.image+'" class="img-fluid mb-2" />';
            // }

            html += '<div><strong>' + config.pointName + '</strong></div>';

            if (config.markerAddress !== "") {
                html += '<div class="address road mt-0 mb-0">' + config.markerAddress + '</div>';
            }

            if (config.markerAddress !== "") {
                html += '<div class="address road mt-0 mb-0">' + config.markerZipcode + ' ' + config.markerCity + '</div>';
            }

            if (config.pointLink !== "" || config.googleMapUrl !== "") {
                html += '<div class="row mt-2">';

                if (config.pointLink !== "") {
                    html += '<div class="col-sm-7"><a href="' + config.pointLink + '">Voir</a></div>';
                }

                if (config.googleMapUrl !== "") {
                    html += '<div class="col-sm-5 text-right"><a href="' + config.markerLinkItinerary + '" class="gmap-link" target="_blank">' + 'Itinéraire' + '</a></div>';
                }

                html += '</div>';
            }

            marker.bindPopup(html);
            markerGroup.push(marker);
        }

        macarte.scrollWheelZoom.disable();
        macarte.setZoom(zoom);
    }
});

$('body').on('click', '.marker-select', function(e) {

    e.preventDefault();

    var el = $(this);
    var markerClass = el.data('markeur');
    var isVisible = el.attr('data-visible');

    if(isVisible == 1) {
        $('body').find('.'+markerClass).addClass('d-none');
        el.attr('data-visible', 0);
        el.removeClass('active');
    }
    else {
        $('body').find('.'+markerClass).removeClass('d-none');
        el.attr('data-visible', 1);
        el.addClass('active');
    }
});